document.addEventListener("turbolinks:load", function () {
  function creatableSelect2Options({
                                     href = '#',
                                     text = 'Создать нового контрагента',
                                     linkHtml = null
                                   } = {}) {
    const link = linkHtml || `<a href="${href}" class="create-new-option text-link">${text}</a>`;

    return {
      language: {
        noResults: function () {
          return `Ничего не найдено. ${link}`;
        },
        inputTooShort: function (args) {
          const remaining = args.minimum - args.input.length;
          return `Для поиска введите ещё ${remaining} символ(а/ов) или ${link}`;
        },
      },
      escapeMarkup: function (markup) {
        return markup;
      }
    };
  }


  function getSelect2Params({ url,
                              formatResults = (v) => ({ id: v.id, text: v.name }),
                              dropdownParent = null,
                              extraOptions = {} ,
                              placeholder = "Выберите из списка"}
  ) {
    const baseOptions =  {
      theme: "bootstrap4",
      allowClear: true,
      width: "100%",
      minimumInputLength: 2, // Минимальная длина ввода
      dataType: "json",
      placeholder: placeholder,
      dropdownParent: dropdownParent,
      ajax: {
        url: url, // Динамический URL
        delay: 250,
        data: function (params) {
          return {search_name: params.term}; // Параметр поиска
        },
        processResults: function (data) {
          return {
            results: $.map(data, formatResults), // Используем переданный callback для форматирования
          };
        },
        cache: true, // Кеширование запросов
      },
    };

    // Глубокое объединение baseOptions + extraOptions
    return $.extend(true, {}, baseOptions, extraOptions);
  };

  if ($(".select2-contractor").length > 0) {
    $(".select2-contractor").select2(getSelect2Params({
      url: '/contractors/search'
    }));
  }

  if ($(".select2-waybill").length > 0) {
    $(".select2-waybill").select2(getSelect2Params({
      url: '/manager/waybills/search'
    }));
  }

  if ($(".select2-contractor.payer").length > 0) {
    $(".select2-contractor.payer").select2(getSelect2Params({
      url: '/contractors/search?is_payer=true',
      extraOptions: creatableSelect2Options({ href: '/ru/contractors/new?order=new', text: 'Создать нового контрагента'})
    }));
  }
  if ($(".select2-contractor.recipient").length > 0) {
    $(".select2-contractor.recipient").select2(getSelect2Params({
      url: '/contractors/search',
      placeholder: 'Выберите или Cоздайте нового получателя',
      extraOptions: creatableSelect2Options({
        linkHtml: `<a id='new_receiver_link' href="#" class="text-link">Создать нового получателя</a>`
      })
    }));
  }

  if ($(".select2-contractor-waybill").length > 0) {
    $(".select2-contractor-waybill").select2(getSelect2Params({
      url: '/contractors/search',
      dropdownParent: $('#modalExportWaybills')
    }));
  }

  if ($(".select2-contractor-modal").length > 0) {
    $(".select2-contractor-modal").select2(getSelect2Params({
      url: '/contractors/search?is_payer=true',
      dropdownParent: $('#filter')
    }));
  }

  if ($(".select2-money-incoming").length > 0) {
    $(".select2-money-incoming").select2(getSelect2Params({
      url: '/manager/money_incomings/search'
    }));
  }


})


