document.addEventListener("turbolinks:load", function () {
  const ruMaskOptions = {
    mask: [
      {
        mask: '+{7}(000)000-00-00',
        startsWith: '7',
        lazy: false,
        country: 'Russia'
      },
      {
        mask: '+{380}(00)000-00-00',
        startsWith: '380',
        lazy: false,
        country: 'Ukraine'
      },
      {
        mask: '+{375}(00)000-00-00',
        startsWith: '375',
        lazy: false,
        country: 'Belarus'
      }
    ],
    dispatch: function (appended, dynamicMasked) {
      let number = (dynamicMasked.value + appended).replace(/\D/g, '');
      if (number.startsWith('8')) {
        number = '7' + number.slice(1);
      }

      return dynamicMasked.compiledMasks.find(m =>
        number.startsWith(m.startsWith)
      );
    }
  };

  document.querySelectorAll('.ru_phone').forEach(input => {
    const raw = input.value.replace(/\D/g, '').replace(/^8/, '7');

    // очищаем поле, чтобы не мешало IMask при инициализации
    input.value = '';

    const mask = IMask(input, ruMaskOptions);

    if (raw.length > 1) {
      mask.typedValue = raw;
    } else {
      mask.typedValue = '7';
    }

    // ловим ввод "8" в начале и заменяем на "7"
    input.addEventListener('beforeinput', function (e) {
      if (
        e.inputType === 'insertText' &&
        e.data === '8' &&
        this.value.trim() === ''
      ) {
        e.preventDefault();
        mask.typedValue = '7';
        setTimeout(() => {
          // ставим курсор сразу после кода страны
          const pos = mask.value.indexOf('(') + 1;
          this.setSelectionRange(pos, pos);
        }, 0);
      }
    });
  });
});
